<!-- 说明：
1.左侧列表输入框下的标题可以自定义，使用插槽customTitle
2.左右两侧列表的名称后缀可以自定义，例如【请选择关联印章】中【停用】的徽章，可以使用customInfo插槽
-->
<template>
  <div class="selector-content">
    <div
      :class="['select-left', params.isShowSelectedList ? 'half-width' : 'full-width']"
      load-more-disabled="false"
      v-loadMore.expand="{ func: leftLoadData, target: '.scroll-list-left', delay: 300, distance: 10 }"
    >
      <div class="left-top">
        <!-- 搜索关键词 -->
        <a-input
          class="model-input"
          v-model:value.trim="searchValue"
          :placeholder="params.placeholder"
          @change="getSearchInfo"
          allow-clear
        >
          <template #prefix>
            <SearchOutlined style="color: rgba(0, 0, 0, 0.25)" />
          </template>
        </a-input>
        <!-- 企业面包屑  / 全选  / 自定义标题-->
        <slot name="customTitle"></slot>
        <a-breadcrumb v-if="params.isShowBreadcrumb">
          <template #separator><span style="color: black">></span></template>
          <a-breadcrumb-item
            href=""
            v-for="(item, index) in breadcrumbList"
            :key="index"
            @click="handledBreadcrumb(item, index)"
          >
            <a-tooltip placement="topRight" :title="item.label" v-if="item.label.length > 8">
              {{ item.label }}
            </a-tooltip>
            <div v-else>
              {{ item.label }}
            </div>
          </a-breadcrumb-item>
        </a-breadcrumb>
        <div class="feature-box" v-if="params.abelCheckAll || params.ableChangeData">
          <span
            :class="[
              'all-sel',
              {
                'all-sel-disabled': isSelectedFlag()
              }
            ]"
            @click="toAllSel"
            v-if="params.abelCheckAll"
          >
            全选人员
          </span>
          <div class="select-type-box" @click="changeData" v-if="params.ableChangeData">
            <!-- 默认组织 -->
            <img :src="isOrganizationData ? personnelIcon : organizationIcon" alt="" class="select-type-icon" />
            <span class="select-type-span">{{ isOrganizationData ? '按人员选择' : '按组织架构选择' }}</span>
          </div>
        </div>
      </div>
      <!-- 左侧列表数据 -->
      <div class="scroll-list-left select-list" ref="scrollRef">
        <template v-if="renderList && renderList.length">
          <div class="list-box" v-for="(item, index) in renderPageList" :key="index" @click="singleCheck(item, index)">
            <a-checkbox
              class="list-checkbox"
              :checked="getCheckState(item)"
              @change="checkItem(item)"
              :disabled="item.disabled"
              v-if="(params.ableSelectDep || item.dataType === 1) && !params.isSingleChoice"
            >
              <div class="info">
                <!-- icon -->
                <div class="icon" :style="{ backgroundImage: `url(${item.icon})` }" />
                <!-- 名称 -->
                <a-tooltip
                  placement="topLeft"
                  :title="item.dataName"
                  v-if="
                    ((item.islower || item.status == 2) && item.dataName.length > 12) ||
                      (!item.islower && item.status != 2 && item.dataName.length > 14) ||
                      (item.mobile && item.dataName.length > 6)
                  "
                >
                  <div class="column">
                    <div class="name-box">
                      <div :class="['name', { 'max-12': item.islower || item.status == 2, 'max-6': item.mobile }]">
                        {{ item.dataName }}
                      </div>
                      <!-- TODO：手机号 -->
                      <span v-if="item.mobile">({{ item.mobile }})</span>
                      <!-- 自定义展示内容 -->
                      <slot :currentItem="item" name="customInfo"></slot>
                    </div>
                    <slot :currentItem="item" name="customDesc"></slot>
                  </div>
                </a-tooltip>
                <template v-else>
                  <div class="column">
                    <div class="name-box">
                      <div class="name">
                        {{ item.dataName }}
                      </div>
                      <!-- TODO：手机号 -->
                      <span v-if="item.mobile">({{ item.mobile }})</span>
                      <!-- 自定义展示内容 -->
                      <slot :currentItem="item" name="customInfo"></slot>
                    </div>
                    <slot :currentItem="item" name="customDesc"></slot>
                  </div>
                </template>
              </div>
            </a-checkbox>
            <div class="info" v-else>
              <!-- icon -->
              <div class="icon" :style="{ backgroundImage: `url(${item.icon})` }" />
              <!-- 名称 -->
              <a-tooltip
                placement="topLeft"
                :title="item.dataName"
                v-if="
                  ((item.islower || item.status == 2) && item.dataName.length > 12) ||
                    (!item.islower && item.status != 2 && item.dataName.length > 14) ||
                    (item.mobile && item.dataName.length > 6)
                "
              >
                <div class="column">
                  <div class="name-box">
                    <div
                      :class="[
                        'name',
                        {
                          'max-12': item.islower || item.status == 2,
                          'max-6': item.mobile,
                          'single-checked-red': params.isSingleChoice && currentIndex === index
                        }
                      ]"
                    >
                      {{ item.dataName }}
                    </div>
                    <!-- TODO：手机号 -->
                    <span
                      v-if="item.mobile"
                      :class="{ 'single-checked-red': params.isSingleChoice && currentIndex === index }"
                      >({{ item.mobile }})</span
                    >
                    <!-- 自定义展示内容 -->
                    <slot :currentItem="item" name="customInfo"></slot>
                  </div>
                  <slot :currentItem="item" name="customDesc"></slot>
                </div>
              </a-tooltip>
              <template v-else>
                <div class="column">
                  <div class="name-box">
                    <div :class="['name', { 'single-checked-red': params.isSingleChoice && currentIndex === index }]">
                      {{ item.dataName }}
                    </div>
                    <!-- TODO：手机号 -->
                    <span
                      v-if="item.mobile"
                      :class="{ 'single-checked-red': params.isSingleChoice && currentIndex === index }"
                      >({{ item.mobile }})</span
                    >
                    <!-- 自定义展示内容 -->
                    <slot :currentItem="item" name="customInfo"></slot>
                  </div>
                  <slot :currentItem="item" name="customDesc"></slot>
                </div>
              </template>
            </div>
            <div
              class="list-left-function"
              v-if="item.islower && !params.isSingleChoice"
              @click.stop="tolowerMdel(item.departmentId, item, '')"
              :class="getCheckState(item) && !params.isSelectDept ? 'model-lower-level-disable' : 'model-lower-level'"
            >
              <span class="next">下级</span>
              <RightOutlined class="right-icon" />
            </div>
            <div class="list-left-function" v-if="params.isSingleChoice && currentIndex === index">
              <CheckOutlined style="color: #c3161c;  font-size: 16px" />
            </div>
          </div>
          <div class="list-bottom-tip">{{ leftIsLoading ? '加载中...' : '到底啦～' }}</div>
        </template>
        <!-- 暂无数据 -->
        <a-empty v-else :image="simpleImage" class="empty" />
      </div>
    </div>
    <div
      class="show-right"
      v-if="params.isShowSelectedList"
      load-more-disabled="false"
      v-loadMore.expand="{ func: rightLoadData, target: '.scroll-list-right', delay: 300, distance: 10 }"
    >
      <div class="show-select-count">
        <div class="show-select">
          已选:{{ params.ableChangeData ? selectedCount : insideSelectList.length }}{{ params.unit }}
        </div>
        <div class="show-limit" v-if="params.limitCount">
          {{ params.ableChangeData ? selectedCount : insideSelectList.length }}/{{ params.limitCount }}
        </div>
      </div>

      <div class="scroll-list-right">
        <template v-if="insideSelectList && insideSelectList.length">
          <div class="list-box" v-for="item in showPageList" :key="item.id">
            <div class="info">
              <!-- icon -->
              <div class="icon" :style="{ backgroundImage: `url(${item.icon})` }" />
              <!-- 名称 -->
              <a-tooltip
                placement="topLeft"
                :title="item.dataName"
                v-if="
                  item.dataName.length > 14 ||
                    (((item.dataMembers !== undefined && !params.isSelectDept) || item.mobile) &&
                      item.dataName.length > 6) ||
                    (item.status == 2 && item.dataName.length > 12)
                "
              >
                <div class="column">
                  <div class="name-box">
                    <div
                      :class="[
                        'name',
                        {
                          'max-6': (item.dataMembers !== undefined && !params.isSelectDept) || item.mobile,
                          'max-12': item.status == 2
                        }
                      ]"
                    >
                      {{ item.dataName }}
                    </div>
                    <span>
                      <!-- 部门下的人数 -->
                      <span v-if="item.dataMembers !== undefined && !params.isSelectDept"
                        >({{ item.dataMembers }}人)</span
                      >
                      <!-- TODO：手机号 -->
                      <span v-if="item.mobile">({{ item.mobile }})</span>
                    </span>
                    <!-- 自定义展示内容 -->
                    <slot :currentItem="item" name="customInfo"></slot>
                  </div>
                  <slot :currentItem="item" name="customDesc"></slot>
                </div>
              </a-tooltip>
              <template v-else>
                <div class="column">
                  <div class="name-box">
                    <div class="name">
                      {{ item.dataName }}
                    </div>
                    <span>
                      <!-- 部门下的人数 -->
                      <span v-if="item.dataMembers !== undefined && !params.isSelectDept"
                        >({{ item.dataMembers }}人)</span
                      >
                      <!-- TODO：手机号 -->
                      <span v-if="item.mobile">({{ item.mobile }})</span>
                    </span>
                    <!-- 自定义展示内容 -->
                    <slot :currentItem="item" name="customInfo"></slot>
                  </div>
                  <slot :currentItem="item" name="customDesc"></slot>
                </div>
              </template>
            </div>
            <CloseCircleFilled @click="uncheck(item)" class="delete-icon" />
          </div>
          <div class="list-bottom-tip">{{ rightIsLoading ? '加载中...' : '到底啦～' }}</div>
        </template>
        <!-- 暂无数据 -->
        <a-empty v-else :image="simpleImage" class="empty" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive, toRefs, computed, watch } from 'vue'
import { RightOutlined, CloseCircleFilled, SearchOutlined, CheckOutlined } from '@ant-design/icons-vue'

import { cmsNotice } from '@/utils/utils'
import { useStore } from 'vuex'
import { Empty } from 'ant-design-vue'
import organizationIcon from '@/assets/images/organization.png'
import personnelIcon from '@/assets/images/personnel.png'

export default defineComponent({
  components: {
    SearchOutlined,
    RightOutlined,
    CloseCircleFilled,
    CheckOutlined
  },
  props: {
    selectorInfo: {
      type: Object,
      default: () => {
        return {
          // placeholder
          // limitCount: [限制数量]
          // abelCheckAll:  [是否显示全选]
          // ableSelectDep: [能否选择部门]
          // isShowBreadcrumb: [是否显示面包屑]
          // isShowSelectedList[是否展示已选的列表]
          // unit[已选列表标题单位]
          // isSingleChoice[是否为单选]
          // isSelectDept[选择部门下的人还是选择部门]
          // isSelectAllDept[是否支持部门全选，仅支持部门当前层级]
          // customDefaultBread[自定义初始化默认面包屑]
          // ableChangeData[是否支持切换组织架构和人员数据]
        }
      }
    },
    renderList: {
      //列表数据[分页数据]
      type: Array,
      default: () => [
        //需通过formateListData()函数获取格式化的数据
      ]
    },
    selectedList: {
      //选中数据[回显][分页数据]
      type: Array,
      default: () => [
        //需要通过formateListData()函数获取格式化的数据
        // {
        //   dataName//数据名称
        //   dataType//数据类型
        //   dataId//Id
        //   icon//头像标识
        //   mobile//手机号
        //   members//部门下人数
        // }
      ]
    },
    visible: {
      //弹窗必传
      type: Boolean,
      default: false
    },
    pageSize: {
      type: Number,
      default: 10
    }
  },
  emits: ['getSearchInfo', 'toNext', 'changeData'],
  setup(props, { emit, expose }) {
    console.log('0-0-0-',props);
    const params = computed(() => {
      return Object.assign(
        {
          placeholder: '',
          limitCount: null, // [限制数量]
          abelCheckAll: false, // [是否显示全选]
          ableSelectDep: true, //[能否选择部门]
          isShowBreadcrumb: true, //[是否显示面包屑]
          isShowSelectedList: true, //[是否展示已选的列表]
          unit: '个', //[已选列表标题单位]
          isSingleChoice: false, //[是否为单选]
          isSelectDept: false, //[选择部门下的人还是选择部门]
          limitWarningInfo: '当前已添加至人员上限请删除已选人员后重试', //选择超过限制个数提示语
          isSelectAllDept: false, //[是否支持部门全选，仅支持部门当前层级]
          customDefaultBread: null, //自定义初始化默认面包屑
          ableChangeData: false //是否支持切换组织架构和人员数据
        },
        props.selectorInfo
      )
    })
    const state = reactive({
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      leftIsLoading: false,
      rightIsLoading: false
    })
    //勾选的总人数
    const selectedCount = ref(0)
    // 展示的数据类型
    const isOrganizationData = ref(true)
    //单选时当前点击项
    const currentIndex = ref(-1)
    const scrollRef = ref(null)
    //右侧列表分页参数
    const rightPageParam = ref({
      currentPage: 1,
      totalPage: 0
    })
    //左侧列表分页参数
    const LeftPageParam = ref({
      currentPage: 1,
      totalPage: 0
    })
    const _ = require('lodash')
    const store = useStore()
    //子组件内部展示的数据列表
    const insideSelectList = ref([])
    const showPageList = ref([])
    const renderPageList = ref([])
    //面包屑
    const breadcrumbList = ref([{ label: store.state.user.userInfo.result.enterpriseName }])
    //搜索框输入的值
    const searchValue = ref('')
    //搜索
    const getSearchInfo = e => {
      console.log('搜索的内容', e.target.value)
      emit('getSearchInfo', e.target.value)
      // 面包屑在搜索时只显示第一级
      breadcrumbList.value.splice(1, breadcrumbList.value.length)
    }
    //全选
    const isSelectedAll = (arr1, arr2) => {
      const arrs = []
      arr2.forEach(item => {
        const data = arr1.find(i => i.dataId === item.dataId)
        data && arrs.push(data)
      })
      return arr2.length ? arrs.length === arr1.length : false
    }
    const isSelectedFlag = () => {
      const flag = params.value.limitCount
        ? insideSelectList.value.length === params.value.limitCount
        : isSelectedAll(
            params.value.isSelectAllDept ? props.renderList : props.renderList.filter(it => it.dataType === 1),
            insideSelectList.value
          )
      return flag
    }

    //左右列表显示数据处理
    const handleList = type => {
      LeftPageParam.value.totalPage = Math.ceil(props.renderList.length / props.pageSize)
      rightPageParam.value.totalPage = Math.ceil(insideSelectList.value.length / props.pageSize)

      if (type === 'right') {
        showPageList.value = [...insideSelectList.value.slice(0, rightPageParam.value.currentPage * props.pageSize)]
        console.log('回显数据33', showPageList.value)
      } else {
        renderPageList.value = [...props.renderList.slice(0, LeftPageParam.value.currentPage * props.pageSize)]
      }
    }

    const toAllSel = () => {
      const flag = isSelectedFlag()
      if (flag) return
      // 限制的个数
      const limitNum = params.value.limitCount
      // 选择超过限制个数提示语
      const limitWarningInfo = params.value.limitWarningInfo
      // 没有个数限制 权限
      if (!limitNum) {
        props.renderList.forEach((e, index) => {
          if (!params.value.isSelectAllDept) {
            //disabled跳过禁用的部门
            if (e.islower || getCheckState(e) || e.disabled) return
          } else {
            if (getCheckState(e) || e.disabled) return
          }

          insideSelectList.value.push({
            avatarId: e.avatarId,
            dataName: e.dataName,
            dataType: e.dataType,
            dataId: e.dataId,
            icon: e.icon,
            dataMembers: e.members === 0 || e.members ? e.members : undefined,
            mobile: e.mobile, // 手机号
            status: e.status, //印章状态
            departmentName: e.departmentName, //TODO:印章所属组织
            departmentNames: e.departmentNames, // 当前用户所属部门
            ownershipName: e.ownershipName
          })
        })
        handleList('right')
        if (params.value.ableChangeData) selectedCount.value = insideSelectList.value.length
        return
      }
      // 个数限制
      if (insideSelectList.value.length === limitNum) {
        cmsNotice('warning', limitWarningInfo)
        return
      }
      props.renderList.forEach((e, index) => {
        //disabled跳过禁用的部门
        if (e.islower || getCheckState(e) || e.disabled) return
        if (insideSelectList.value.length === limitNum) return
        //跳过禁用的部门
        if (e.disabled) return
        insideSelectList.value.push({
          avatarId: e.avatarId,
          dataName: e.dataName,
          dataType: e.dataType,
          dataId: e.dataId,
          icon: e.icon,
          dataMembers: e.members === 0 || e.members ? e.members : undefined,
          mobile: e.mobile,
          status: e.status, //印章状态
          departmentName: e.departmentName, //印章所属组织
          departmentNames: e.departmentNames, // 当前用户所属部门
          ownershipName: e.ownershipName
        })
      })
      if (params.value.ableChangeData) selectedCount.value = insideSelectList.value.length
      handleList('right')
    }
    //取消勾选
    const uncheck = item => {
      console.log('删除的item', item)
      insideSelectList.value.splice(
        insideSelectList.value.findIndex(it => it === item),
        1
      )
      if (params.value.ableChangeData) selectedCount.value -= item.dataMembers !== undefined ? item.dataMembers : 1
      handleList('right')
    }
    // 获取左list单项选中状态，判断右列表是否存在该数据
    const getCheckState = item => {
      return insideSelectList.value.findIndex(it => it.dataType === item.dataType && it.dataId === item.dataId) >= 0
    }
    // 切换选中状态，在右列表中删掉或增加数据，选中状态还是根据 getCheckState() 判断
    const checkItem = e => {
      console.log('勾选了e的值', e)
      const checked = getCheckState(e)
      // 选择超过限制个数提示语
      const limitWarningInfo = params.value.limitWarningInfo

      console.log('checked的值', checked)
      if (checked) {
        const index = insideSelectList.value.findIndex(it => it.dataType === e.dataType && it.dataId === e.dataId)
        insideSelectList.value.splice(index, 1)
        if (params.value.ableChangeData) selectedCount.value -= e.members !== undefined ? e.members : 1
      } else {
        console.log('部门下的人员数量', e.members, selectedCount.value, e.members === undefined)
        if (params.value.ableChangeData) {
          //开启了部门人员切换数据时，选中部门实际选中该部门下的所有人员，人员限制应遵循实际部门人员数
          selectedCount.value += e.members !== undefined ? e.members : 1
          console.log('选择的人员数量', selectedCount.value, params.value.limitCount)

          if (params.value.limitCount && selectedCount.value > params.value.limitCount) {
            selectedCount.value -= e.members !== undefined ? e.members : 1
            console.log('选择数量', selectedCount.value)
            cmsNotice('warning', limitWarningInfo)
            return
          }
        } else {
          // 判断是否超过限制个数
          if (params.value.limitCount && insideSelectList.value.length === params.value.limitCount)
            return cmsNotice('warning', limitWarningInfo)
        }

        insideSelectList.value.push({
          avatarId: e.avatarId,
          dataName: e.dataName,
          dataType: e.dataType,
          dataId: e.dataId,
          icon: e.icon,
          dataMembers: e.members === 0 || e.members ? e.members : undefined,
          mobile: e.mobile, // 手机号
          status: e.status, //印章状态
          departmentName: e.departmentName, //印章所属组织
          departmentNames: e.departmentNames, // 当前用户所属部门
          ownershipName: e.ownershipName
        })
      }
      handleList('right')
    }
    //展示下级内容
    const tolowerMdel = async (id, item, flg) => {
      // 已选中部门，子集禁用
      if (item != '' && getCheckState(item) && !params.value.isSelectDept) return
      //TODO：传递id给父组件请求接口
      emit('toNext', id)
      if (flg === 'jump') return
      breadcrumbList.value.push({
        label: item.departmentName,
        id: item.departmentId
      })
    }
    //面包屑点击
    const handledBreadcrumb = (e, i) => {
      breadcrumbList.value.splice(i + 1, breadcrumbList.value.length - i)
      tolowerMdel(e.id, '', 'jump')
    }
    //弹窗取消事件
    const cancelEvent = () => {
      if (params.value.ableChangeData) defaultData()
      insideSelectList.value = []
      searchValue.value = ''
      emit('getSearchInfo', '')
      breadcrumbList.value = [{ label: store.state.user.userInfo.result.enterpriseName }]
      currentIndex.value = -1
    }
    //弹窗确定事件
    const okEvent = () => {
      if (params.value.ableChangeData) defaultData()
      let arr = _.cloneDeep(insideSelectList.value)
      console.log('查看选中的数据', arr)
      return arr
    }
    //默认数据处理
    const defaultData = () => {
      // 默认显示组织数据
      isOrganizationData.value = true
      breadcrumbList.value.splice(1, breadcrumbList.value.length)
      emit('changeData', { isOrganizationData: true, searchValue: '' })
    }
    watch(
      () => props.renderList,
      (newRenderList, oldRenderList) => {
        if (newRenderList) {
          LeftPageParam.value.currentPage = 1
          handleList('left')
        }
      }
    )
    watch(
      () => props.visible,
      () => {
        console.log(params.value.customDefaultBread, '------哈哈哈------')

        if (props.visible) {
          console.log('查看左侧列表的滚动高度', scrollRef.value?.scrollTop)
          //回显展示具体到人
          let arr = _.cloneDeep(props.selectedList)
          insideSelectList.value = arr
          console.log('已经选择的数据', insideSelectList.value)

          rightPageParam.value.currentPage = 1
          LeftPageParam.value.currentPage = 1
          //防止immediate初始化时报错
          handleList && handleList('right')
          handleList && handleList('left')
          setTimeout(() => {
            scrollRef.value && (scrollRef.value.scrollTop = 0)
          }, 500)
          //默认初始面包屑
          if (params.value.customDefaultBread) breadcrumbList.value = [...params.value.customDefaultBread]
          // console.log('当前页', LeftPageParam.value, rightPageParam.value)

          console.log('回显选择的数据有哪些', insideSelectList.value)
          if (params.value.ableChangeData) {
            selectedCount.value = 0
            insideSelectList.value.forEach(item => {
              console.log('回显item', item)
              selectedCount.value += item.dataMembers !== undefined ? item.dataMembers : 1
            })
          }
        }
      },
      {
        immediate: true
      }
    )
    //左侧列表加载数据
    const leftLoadData = () => {
      if (LeftPageParam.value.currentPage >= LeftPageParam.value.totalPage) {
        state.leftIsLoading = false
        return
      }
      state.leftIsLoading = true
      setTimeout(() => {
        LeftPageParam.value.currentPage++
        handleList('left')
      }, 500)
    }
    //右侧列表加载数据
    const rightLoadData = () => {
      if (rightPageParam.value.currentPage >= rightPageParam.value.totalPage) {
        state.rightIsLoading = false
        return
      }
      state.rightIsLoading = true
      setTimeout(() => {
        rightPageParam.value.currentPage++
        handleList('right')
      }, 500)
    }

    //单选
    const singleCheck = (item, index) => {
      if (!params.value.isSingleChoice) return
      currentIndex.value = index
      insideSelectList.value = [item]
    }
    //TODO:切换数据
    const changeData = () => {
      isOrganizationData.value = !isOrganizationData.value
      emit('changeData', { isOrganizationData: isOrganizationData.value, searchValue: searchValue.value })
      console.log('看看现在右侧有哪些数据呢', insideSelectList.value)
      console.log('切的时候看看搜索内容呗', searchValue.value)
      breadcrumbList.value.splice(1, breadcrumbList.value.length)
      setTimeout(() => {
        scrollRef.value && (scrollRef.value.scrollTop = 0)
      }, 500)
    }
    expose({ cancelEvent, okEvent })
    onMounted(() => {
      let arr = _.cloneDeep(props.selectedList)
      insideSelectList.value = arr
      rightPageParam.value.currentPage = 1
      LeftPageParam.value.currentPage = 1
      handleList('right')
      handleList('left')
    })

    return {
      ...toRefs(state),
      getSearchInfo,
      searchValue,
      toAllSel,
      isSelectedFlag,
      getCheckState,
      uncheck,
      checkItem,
      tolowerMdel,
      breadcrumbList,
      handledBreadcrumb,
      params,
      cancelEvent,
      okEvent,
      insideSelectList,
      leftLoadData,
      rightLoadData,
      rightPageParam,
      showPageList,
      LeftPageParam,
      renderPageList,
      handleList,
      scrollRef,
      currentIndex,
      singleCheck,
      organizationIcon,
      personnelIcon,
      changeData,
      isOrganizationData,
      selectedCount,
      defaultData
    }
  }
})
</script>
<style lang="less" scoped>
.selector-content {
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(0, 0, 0, 0.06);
  height: 100%;

  .select-left {
    padding: 16px 14px;
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: column;

    .left-top {
      flex: 0 0 auto;

      .model-input {
        margin-bottom: 12px;
      }

      .all-sel {
        cursor: pointer;
        color: #1890ff;
        margin: 5px 0;
        display: inline-block;
      }

      .all-sel-disabled {
        color: #969799;
      }

      :deep(.ant-breadcrumb a) {
        color: #333333;
        font-size: 12px;
        line-height: 12px;
        max-width: 110px;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        direction: rtl;
      }

      :deep(.ant-breadcrumb > span:last-child a) {
        color: #0A7BFF;
        font-size: 12px;
      }
    }

    .select-list {
      margin-top: 12px;
    }
  }

  .half-width {
    width: 50%;
  }

  .full-width {
    width: 100%;
  }

  .show-right {
    padding: 16px;
    width: 50%;
    display: flex;
    flex-direction: column;

    .show-select-count {
      margin-bottom: 26px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex: 0 0 auto;

      .show-limit {
        color: #969799;
      }
    }
  }

  // 滚动列表
  .scroll-list-left,
  .scroll-list-right {
    position: relative;
    overflow-y: scroll;
    flex-grow: 1;

    .list-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .list-checkbox {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .info {
        display: flex;
        flex-direction: row;
        align-items: center;

        .icon {
          width: 26px;
          height: 26px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          margin: 0 8px 0 0;
          border-radius: 50%;
          flex: 0 0 auto;
        }
        .name-box {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .name {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 14em;
          width: fit-content;
        }
        .single-checked-red {
          color: #c3161c;
        }

        .max-12 {
          max-width: 12em;
        }
        .max-6 {
          max-width: 6em;
        }

        .badge {
          width: 32px;
          height: 18px;
          background: #ebedf0;
          border-radius: 2px;
          text-align: center;
          line-height: 18px;
          font-size: 12px;
          font-weight: 400;
          color: #323233;
          flex: 0 0 auto;
          margin-left: 4px;
        }
      }

      .delete-icon {
        color: #cccccc;
        flex: 0 0 auto;
      }

      .list-left-function {
        display: flex;
        align-items: center;
        flex: 0 0 auto;

        .next {
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          // color: #0A7BFF !important;
        }
      }

      .model-lower-level {
        .right-icon,
        .next {
          color: #0A7BFF;
          cursor: pointer;
        }
      }

      .model-lower-level-disable {
        .right-icon,
        .next {
          color: #b8b8b8;
          cursor: not-allowed;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .list-bottom-tip {
      margin-top: 12px;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }

    .empty {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0 !important;
    }
  }
}
//切换人员组织选择
.feature-box {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  cursor: pointer;
  .select-type-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    right: 0;
    .select-type-icon {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
    .select-type-span {
      font-weight: 400;
      font-size: 14px;
      color: #1890ff;
      line-height: 20px;
    }
  }
}
</style>
